import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"
import { Card, CardBody } from 'reactstrap'
import { navigate } from 'gatsby'
import { CheckToken } from '../helpers/utils'
import DashboardLayout from '../layouts/dashboardlayout'

const Profile = () => {
  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }
  }, [])

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - My Account</title>
      </Helmet>
    <DashboardLayout>
      <>
        <Card className="border-bottom">
          <CardBody className="overflow-hidden p-lg-6  card-inside">
            <h1>MY ACCOUNT</h1>
          </CardBody>
        </Card>
      </>
      </DashboardLayout>
      </>
  )
}

export default Profile
